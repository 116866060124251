@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    @apply text-neutral-130 font-cabin;
  }

  // .k-grid {
  //   font-size: 0.7rem !important;

  //   th {
  //     font-size: 0.7rem !important;
  //   }

  //   td {
  //     font-size: 0.7rem !important;
  //   }
  // }

  .buttons-container-button {
    @apply flex items-center;

    .k-button-text {
      @apply flex-1;
    }
  }

  .k-treeview {
    padding: 0 0.35rem 0rem 0rem !important;
    font-size: 0.7rem;

    // .k-treeview-leaf {
    //   --INTERNAL--kendo-treeview-item-padding-x: var(--kendo-treeview-sm-item-padding-x, 0.25rem) !important;
    //   --INTERNAL--kendo-treeview-item-padding-y: var(--kendo-treeview-sm-item-padding-y, 0) !important;
    // }
    .k-checkbox-sm {
      --INTERNAL--kendo-checkbox-width: 0.75rem;
      --INTERNAL--kendo-checkbox-height: 0.75rem;
      --INTERNAL--kendo-checkbox-glyph-size: 0.75rem;
      --INTERNAL--kendo-checkbox-indicator-size: 0.65rem;
      --INTERNAL--kendo-checkbox-indeterminate-size: 0.75rem;
    }
  }

  .card {
    @apply rounded-[2px] shadow-elevation4 bg-white;
  }

  .customTabs {
    .k-tabstrip-items-wrapper {
      @apply mb-3;
    }

    .k-child-animation-container {
      padding: 3px;
    }

    .k-link {
      @apply sm:px-[0.5rem] px-[0.2rem];
    }

    .k-tabstrip-content {
      @apply bg-transparent;
    }

    .k-animation-container {
      @apply w-full;
    }

    .k-tabstrip-items {
      @apply sm:gap-[0.5rem] gap-[0.1rem];
    }

    span {
      @apply sm:text-[12px] text-[11px];
    }
  }

  .loginField {
    input {
      @apply pr-[32px];
    }
  }

  .customTabs {
    .k-tabstrip-content {
      @apply p-0;
    }
  }

  .leftTabs {
    .k-tabstrip-items-wrapper {
      @apply flex-[0_0_255px] px-4 shadow-elevation4;

      .k-tabstrip-items {
        @apply gap-0;

        .k-item {
          @apply text-sm border-b border-neutral-30 focus:outline-none focus:shadow-none;
        }

        .k-active {
          @apply bg-neutral-30;

          &:after {
            @apply content-[none];
          }
        }
      }
    }

    .k-tabstrip-content {
    }

    .k-animation-container {
      @apply w-full;
    }
  }

  .customDilogue {
    .k-dialog-titlebar {
      @apply px-4 py-5;

      .k-dialog-title {
        @apply p-0;
      }
    }

    .k-dialog-content {
      @apply p-0;
    }
  }

  .iconBtn {
    span {
      @apply w-full text-left;
    }
  }

  .customHeight {
    .k-grid-content {
      @apply overflow-y-auto;
    }

    .k-grid-header {
      @apply p-0;
    }
  }

  .customCheckbox {
    .k-input-values {
      @apply items-start flex-nowrap;
    }

    input {
      @apply h-full;
    }
  }

  .tabsTopBar {
    .k-tabstrip-items {
      li {
        span {
          @apply 2xl:min-w-[100px] min-w-[80px] justify-center;
        }

        &.k-active {
          @apply bg-neutral-30;
        }
      }

      @apply xl:justify-end;
    }

    .k-tabstrip-content {
      @apply hidden;
    }

    &.justifyStart {
      .k-tabstrip-items {
        @apply justify-start;
      }
    }
  }

  .LookUpTabs {
    @apply flex flex-1; //overflow-x-hidden overflow-y-auto

    .sidebar-lookups {
      @apply flex-[0_0_255px] shadow-elevation8 px-4 sticky top-0 overflow-y-auto;

      .k-tabstrip-item {
        @apply border-b border-neutral-30 last-of-type:border-none;

        &.k-active {
          @apply bg-neutral-30;
        }

        &:after {
          @apply content-[none];
        }
      }
    }

    .content-lookups {
      @apply flex-1;
    }

    .k-tabstrip-items-wrapper {
      @apply w-full;
    }

    .k-tabstrip-content {
      @apply hidden;
    }
  }

  .customDrawer2 {
    @apply fixed inset-0 z-[999] opacity-0 invisible;

    &.k-drawer-expanded {
      @apply opacity-100 visible;
    }

    .overlay {
      @apply fixed inset-0 bg-black/50;
    }
  }

  .overlay {
    @apply fixed inset-0 bg-black/50;
  }

  .customDrawer {
    @apply fixed inset-0 z-[999] opacity-0 invisible;
    z-index: 99999;

    &.k-drawer-expanded {
      @apply opacity-100 visible;
    }

    .overlay {
      @apply fixed inset-0 bg-black/50;
    }
  }

  .notification {
    @apply fixed inset-0 z-[999] opacity-0 invisible;

    &.k-drawer-expanded {
      @apply opacity-100 visible;
    }
  }

  .menuPopup {
    @apply min-w-[161px] w-full;
  }

  .customCheckbox {
    @apply h-[34px];
  }

  .cstBtn {
    @apply h-[34px];

    .k-button-text {
      @apply inline-flex items-center;
    }
  }

  .staffList {
    .k-chip-actions {
      @apply hidden;
    }

    .k-chip-content {
      background-color: #c7e0f4;
      @apply m-0 px-3;
    }

    .k-clear-value {
      display: none;
    }
  }

  .applicationTabs {
    overflow-y: auto;
    flex: 1;

    .k-tabstrip-content {
      padding-bottom: 80px;
    }

    .k-tabstrip-left {
      height: 100%;
    }

    .k-tabstrip-items-wrapper {
      @apply flex-[0_0_295px] py-5 px-3 bg-white shadow-elevation8 sticky top-0 overflow-y-auto z-[9999];

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.5);
      }

      &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.5);
      }

      .k-item {
        @apply after:left-0 after:right-auto;

        &.k-active {
          @apply bg-neutral-30;
        }
      }

      .k-link {
        @apply px-3;
      }
    }

    .k-tabstrip-content {
      @apply bg-transparent;

      .k-animation-container {
        @apply w-full;
      }
    }

    .cstIconBtn {
      @apply p-0;

      span {
        @apply h-full w-full;

        .error {
          @apply h-full px-[1.25rem] py-1;
        }

        .success {
          @apply h-full px-[1.25rem] py-1;
        }
      }

      &.selected {
        .error {
          @apply bg-[#FED9CC];
        }

        .success {
          @apply bg-[#DFF6DD];
        }
      }
    }
  }

  .dependentsTabs {
    ul {
      @apply mb-5;
    }

    .k-tabstrip-content {
      padding-bottom: 100px;
    }

    .k-animation-container {
      @apply w-full;
    }
  }
}

// .LookUpTabs .k-tabstrip-item {
//   border-bottom: 1px solid #edebe9 !important;
// }

.LookUpTabs .k-tabstrip-item:last-of-type {
  border: none !important;
}

.k-tabstrip-content {
  + {
    div {
      display: none !important;
    }
  }
}

.k-animation-container {
  z-index: 10003 !important;
}

.panels {
  .k-animation-container {
    z-index: 999 !important;
  }
}

.disabled:disabled + label {
  background: #f3f2f1;
  opacity: 0.5;
  pointer-events: none;
}

.btnMenu {
  span {
    @apply text-left w-full;
  }
}

button {
  span {
    @apply inline-flex;
  }
}

.cstfile:disabled + label {
  background: #faf9f8;
  border: 1px solid #edebe9;
  cursor: not-allowed;
}

.leaseTable {
  th,
  td {
    border-right: none !important;
  }

  .k-grid-content {
    max-height: calc(100vh - 339px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .k-table-row {
    &:hover {
      transition: all 0.3s;

      .cta_dropdown {
        @apply visible opacity-100;
      }
    }
  }
}

.lookUpsTable {
  .k-grid-content {
    max-height: calc(100dvh - 300px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}

.listStatus {
  li {
    &:last-of-type {
      &:after {
        content: none;
      }
    }
  }
}

.setting-page {
  .k-grid-content {
    overflow: auto;

    @media (min-width: 1200px) {
      max-height: calc(100vh - 314px);
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  &.allocations {
    .k-toolbar {
      ~ {
        .k-grouping-header {
          display: none;
        }
      }
    }

    .k-grid-content {
      overflow-y: auto;
    }

    .k-reset {
      a[aria-label="Collapse Group"] {
        .k-icon {
          background: url(./assets/images/chevron-up.svg);
          transform: rotate(180deg);

          svg {
            display: none;
          }
        }
      }

      a[aria-label="Expand group"] {
        .k-icon {
          background: url(./assets/images/chevron-up.svg);

          svg {
            display: none;
          }
        }
      }
    }
  }
}

.cst-scroll::-webkit-scrollbar {
  width: 5px;
}

.cst-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.cst-scroll::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
}

.cst-scroll::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}

.column-menu-checkbox .k-actions button[type="submit"] {
  color: white !important;
  background-color: #0078d4;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.k-pager-numbers .k-button .k-button-text {
  justify-content: center;
}

.LookUpTabs .k-tabstrip-scrollable > .k-tabstrip-items-wrapper > .k-button {
  display: none;
}

@media (max-width: 991px) {
  .docsTabs.k-tabstrip-left {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .docsTabs .k-tabstrip-items-wrapper {
    flex: inherit;
    box-shadow: none;
  }

  .leftTabs.docsTabs .k-tabstrip-items-wrapper .k-tabstrip-items .k-item {
    min-width: 100px;
    display: inline-flex;
    justify-content: center;
  }

  .docsTabs .k-tabstrip-items-wrapper {
    margin-bottom: 20px;
  }

  .docsTabs .k-tabstrip-content,
  .docsTabs .k-tabstrip > .k-content {
    padding: 0 1rem !important;
  }

  .LookUpTabs .k-tabstrip-scrollable > .k-tabstrip-items-wrapper > .k-button {
    display: inline-flex;
    background: #f0f9ff !important;
  }

  .LookUpTabs {
    flex-direction: column;
  }

  .LookUpTabs .k-tabstrip-items-wrapper .k-tabstrip-items .k-item {
    min-width: 100px;
    display: inline-flex;
    justify-content: center;
  }

  .LookUpTabs .sidebar-lookups {
    flex: inherit;
    box-shadow: none;
  }

  .LookUpTabs .sidebar-lookups .k-tabstrip-item {
    border: none !important;
  }
}

.k-svg-i-caret-alt-down {
  background-image: url("../public/images/arrow-chevron-down.svg");
  background-repeat: no-repeat;
  background-position: center;

  svg {
    display: none;
  }
}

.clrBtn:hover {
  svg {
    path {
      fill: white;
    }
  }
}

.view-only {
  .k-input:disabled,
  .k-input[disabled],
  .k-input.k-disabled {
    background: #f5f5f5;
    color: #323130;
  }

  .k-multiselect {
    pointer-events: none;
    border: none;

    input {
      background: #f5f5f5;
      border: none;
    }
  }

  .k-button-group {
    &.k-disabled {
      .k-button {
        cursor: not-allowed;
        pointer-events: all;

        &:hover {
          box-shadow: none;
          border: 1px solid transparent;
        }

        &.k-selected {
          color: #323130;
        }
      }
    }
  }

  .k-icon-button {
    .k-svg-i-caret-alt-down {
      display: none;
    }
  }

  textarea {
    resize: none;
  }

  .k-datepicker {
    button {
      display: none;
    }
  }
}

.k-button:focus::after,
.k-button.k-focus::after {
  outline: none;
}

.LookUpTabs .k-table-row:hover .cta_dropdown {
  opacity: 1;
  visibility: visible;
}

.cstToggle {
  &.k-button.k-selected {
    background: transparent;
  }

  &.active {
    background: #cee5ff !important;
  }
}

.customTabs .k-table-row:hover .cta_dropdown {
  opacity: 1;
  visibility: visible;
}

.cstToggle {
  &.k-button.k-selected {
    background: transparent;
  }

  &.active {
    background: #cee5ff !important;
  }
}

.reports-page {
  .LookUpTabs {
    display: block;
  }
}

.reports-page .setting-page .k-grid-content {
  max-height: calc(100vh - 325px);
}

.bbs-treeView {
  .k-treeview {
    height: calc(100vh - 20px);
    padding-bottom: 3rem !important;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.5);
    }

    .k-treeview-item {
      padding-inline-start: var(--kendo-treeview-indent, 0.55rem);

      // .k-treeview-top {
      //   padding-inline-start: var(--kendo-treeview-indent, 0.75rem);
      // }
    }

    .k-treeview-leaf {
      --INTERNAL--kendo-treeview-item-padding-x: var(--kendo-treeview-sm-item-padding-x, 0rem);
      --INTERNAL--kendo-treeview-item-padding-y: var(--kendo-treeview-sm-item-padding-y, 0rem);
    }

    .input {
      height: 30px !important;
    }

    .k-treeview-toggle .k-icon {
      padding: 0;
    }
    .k-treeview-toggle {
      margin-inline-start: 0;
    }
  }

  .k-treeview-leaf.k-selected {
    border: none;
    outline: none;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background-color: #dbdbdb !important;
  }

  .k-treeview-leaf.k-focus {
    background-color: #dbdbdb !important;
    border: none;
    outline: none;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  // .k-treeview-leaf.k-focus {
  //   @apply border-none outline-none bg-transparent shadow-none;
  // }

  .k-checkbox:checked {
    @apply border-primary-130 bg-primary-130;
  }

  .k-treeview-toggle {
    .k-icon {
      svg {
        display: none;
      }

      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23323130' fill-rule='evenodd' d='M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06z' clip-rule='evenodd'/%3E%3C/svg%3E");
      background-size: 0.75rem;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .k-treeview-item {
    &[aria-expanded="false"] {
      .k-treeview-toggle {
        .k-icon {
          transform: rotate(-90deg);
          transition: all 0.3s linear ease-in-out;
        }
      }
    }
  }

  .k-treeview-leaf-text,
  .k-treeview-leaf {
    flex: 1;
  }

  .k-treeview-leaf-text {
    display: flex;
    align-items: center;
  }
}

.custom-dialog {
  min-width: 250px;
  max-width: 100%;
  z-index: 9998 !important;

  .k-dialog-wrapper {
    .k-dialog {
      max-width: 500px;
      width: 100%;
    }
  }
}

.image-dialog {
  z-index: 100002 !important;
}

.upload-import {
  .k-upload {
    border: none;
    padding: 0;

    .k-file,
    .k-upload-files {
      display: none;
    }

    .k-dropzone {
      background: transparent;
      padding: 0;

      .k-upload-status,
      .k-dropzone-hint {
        display: none;
      }

      .k-upload-button-wrap {
        width: 100%;
        display: flex;
      }
    }
  }
}

.customDrawer2 {
  z-index: 9999;
}

.customDrawer3 {
  z-index: 10005;
}

.k-selected .k-treeview-leaf-text .project-text {
  display: flex;
  // color: #0078d4 !important;
}

.project-text {
  display: flex;
  column-gap: 0;
}

.sticky-buttons {
  position: sticky;
  bottom: 0;
  background-color: inherit;
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 9999;
}

.k-grid-norecords-template {
  border: 0;
}

// input,
// input:not([placeholder="Search"]) {
//   border: 1 !important;
//   border-width: 1px !important;
//   border-radius: 0.375rem !important;
//   --tw-border-opacity: 1 !important;
//   border-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
// }

.scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5);
  }
}

.sample-drag-bar {
  flex-shrink: 0;
  width: 3px;
  background-color: #d1d5db;
  cursor: col-resize;
  transition: background-color 0.3s 0.3s ease-in-out;
}

.sample-drag-bar.sample-drag-bar--dragging,
.sample-drag-bar:hover {
  background-color: #63b3ed;
}

.sample-drag-bar.sample-drag-bar--horizontal {
  height: 5px;
  width: 100%;
  cursor: row-resize;
}

.dragging {
  filter: blur(5px);
  background-color: #555555;
}

.LookUpTabs .k-tabstrip-content.k-active {
  display: block;
}
.members-editor {
  .k-input-spinner {
    display: none;
  }
}

.k-grid {
  .k-table {
    font-size: 12px;
  }
  .k-button {
    height: 34px !important;
  }

  .k-column-title {
    min-width: 0;
    text-overflow: ellipsis;
    white-space: pre-line;
  }

  // th {
  //   text-align: center;
  // }

  // .k-link {
  //   justify-content: center;
  // }
}
.k-toolbar .k-input,
.k-toolbar .k-picker,
.k-toolbar .k-combobox,
.k-toolbar .k-dropdown {
  height: 34px !important;
}

button {
  font-weight: normal !important;
}

.k-grid-edit-cell {
  padding: 5px 0;
  .k-input,
  .k-dropdownlist {
    height: 30px;
    background: white;
    font-size: 10px;
    padding: 0px 10px;
  }

  .k-numerictextbox {
    padding: 0 10px;
  }
}

.members-editor .k-input .k-input-inner {
  padding: 0 !important;
}

.members-editor .k-input-value-text {
  font-size: 10px;
}

.detailingGrid {
  .k-grid-edit-cell {
    .k-input,
    .k-dropdownlist,
    .k-numerictextbox {
      font-size: 12px;
    }
  }
}

.headingIcon {
  align-items: center;
}

// .font-light {
//   font-weight: 200;
// }

// .font-medium {
//   font-weight: 300;
// }

.k-switch::after {
  outline: 0 !important;
}
.k-tabstrip,
.k-tabstrip-content {
  background-color: initial !important;
  padding: 0;
}

.show-menu {
  .sidepanel {
    padding-left: 100px;
    transition: 0.3s linear;
  }
}

.k-notification-group {
  z-index: 999999 !important;
}

.inline-edit-cell {
  .k-dialog-wrapper {
    z-index: 100000 !important;
  }
}

.k-notification {
  height: 50px;
  font-size: 14px;
  font-weight: 500;
}
.k-grid .k-grid-header-menu {
  svg {
    display: none;
  }

  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%23323130' d='M9 15.25a1.25 1.25 0 1 1 2.5 0a1.25 1.25 0 0 1-2.5 0m0-5a1.25 1.25 0 1 1 2.5 0a1.25 1.25 0 0 1-2.5 0m0-5a1.249 1.249 0 1 1 2.5 0a1.25 1.25 0 1 1-2.5 0'/%3E%3C/svg%3E");
}

.k-chart {
  border-style: none;
}

.k-pdf-viewer {
  .k-canvas {
    background-color: #3d3d3d;
  }
}

.border-b {
  border-color: inherit !important;
  border-top-width: 0 !important;
}

.border-tb {
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
  border-color: inherit !important;
}

.k-multiselect input {
  height: 40px;
}

.k-treeview-leaf-text {
  .inactive .project-text {
    color: rgb(221, 32, 32) !important;
  }

  .released {
    background-color: #c7e0f4 !important;
  }

  .partially-released {
    color: #63b3ed !important;
  }
}

.k-button-md {
  --INTERNAL--kendo-button-padding-x: var(--kendo-button-md-padding-x, 1rem) !important;
  --INTERNAL--kendo-button-padding-y: var(--kendo-button-md-padding-y, 0.475rem) !important;
}

.k-switch-label-on,
.k-switch-label-off {
  display: var(--kendo-switch-label-display, block) !important;
}

.bbsGrid .k-table,
.callOffGrid .k-table {
  font-size: 11px !important;
}

.k-treeview-item > *:last-child .k-animation-container > *:last-child .k-treeview-item {
  padding-inline-start: var(--kendo-treeview-indent, 1.1rem) !important;
}

.pasted-row {
  background-color: #edebe9;
}

.k-grid td.k-selected,
.k-grid .k-table-row.k-selected > td,
.k-grid .k-table-td.k-selected,
.k-grid .k-table-row.k-selected > .k-table-td {
  background-color: var(--kendo-grid-selected-bg, var(--kendo-primary-20, inherit)) !important;
}

.inactive {
  color: rgb(221, 32, 32) !important;
}

.released {
  background-color: #c7e0f4 !important;
}

.partially-released {
  color: #63b3ed !important;
}

.k-datetime-footer {
  .k-button-solid-primary{
    span {
      color: #63b3ed;
    }
  }
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100% !important;
}
